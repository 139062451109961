<template>

    <div class="section d-flex">
    
        <div class="menu_section hidden-md-and-down"></div>

        <div class="container_section">

            <v-container style="height: 400px;">

                <v-row
                    class="fill-height"
                    align-content="center"
                    justify="center"
                    >
        
                    <v-col
                    class="subtitle-1 text-center"
                    cols="12"
                    >
                    
                        <span>{{ $t('Getting your data') }}</span>

                    </v-col>

                    <v-col cols="6">

                        <v-progress-linear
                            indeterminate
                            rounded
                            height="6"
                            :color="app.state.style.primary"
                        ></v-progress-linear>

                    </v-col>

                </v-row>

            </v-container>
            
        </div>

    </div>
    
</template>

<script>
    
import { inject, onMounted } from '@vue/composition-api' ;
import firebase from "firebase";

export default {
    
    setup(props, ctx){
    
        const app = inject('app') ;

        let token = ctx.root.$route.params.auth_token ;

        console.log('ctx.root.$route ', ctx.root.$route) ;
        console.log('Logging in ', token) ;

        onMounted(() => {

            if(ctx.root.$route.query?.redirect != null){
                console.log('location.host', "https://"+location.host) ;
                app.state.redirect = decodeURI(ctx.root.$route.query.redirect) ;
                app.state.redirect = app.state.redirect.replace("https://"+location.host, "");
            }

            firebase.auth().signInWithCustomToken(token)
            .then(() => {
                
                if(app.state.redirect != null){
                    ctx.root.$router.push(app.state.redirect) ;
                }else{
                    ctx.root.$router.push('/administrations/select') ;
                }

            })
            .catch((error) => {

                console.log('error ', error) ;

                var errorCode = error.code;
                var errorMessage = error.message;

                app.setError(errorMessage+" "+errorCode) ;

            });
            
        });

        return {
            app,
        }
    
    },

};

</script>